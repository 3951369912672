import { Component } from '@angular/core';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './config/config';
import { Router } from '@angular/router';
import { UIStateService } from './services/ui-state.service';
import { ApiService } from './services/api.service';
import { DEFAULT_VEHICLE } from './config/config';
import {pairwise} from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  claims: any;
  token: any;
  constructor(
    private oauthService: OAuthService,
    private apiService: ApiService,
    readonly router: Router,
    readonly appState: UIStateService
  ) {
    this.configureSingleSignOn();
    this.appState.getState$().pipe(pairwise()).subscribe(([previousState, currentState]) => {
      if (previousState.vehicle !== currentState.vehicle) {
        const { sub } = currentState.claims;
        this.apiService.getConfig(currentState.vehicle, sub).subscribe((data) => {
          this.appState.setState({
            last_modified_date: data.last_modified_date.split(',')[0],
            token: data.token
          });
        });
      }
    });
  }

  logout() {
    // If you want to revoke the existing access token and the existing refresh token before logging out, use the following method:
    this.oauthService.revokeTokenAndLogout();
    this.oauthService.logOut();
    this.router.navigateByUrl('/');
  }
  async configureSingleSignOn(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      if (window.location.hostname === 'localhost') {
        this.oauthService.configure(authCodeFlowConfig.local);
      } else if (window.location.hostname === 'one.cvodev.toyota.com') {
        this.oauthService.configure(authCodeFlowConfig.dev);
      } else if (window.location.hostname === 'one.cvoqa.toyota.com') {
        this.oauthService.configure(authCodeFlowConfig.qa);
      } else if (window.location.hostname === 'one.cvo.toyota.com') {
        this.oauthService.configure(authCodeFlowConfig.prod);
      }
      // setup oauthService
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      this.oauthService
        .loadDiscoveryDocumentAndLogin({
          customHashFragment: window.location.search,
        })
        .then((isLoggedIn) => {
          if (isLoggedIn) {
            this.oauthService.setupAutomaticSilentRefresh();
            const claims = this.oauthService.getIdentityClaims();
            this.apiService.getBaseline(DEFAULT_VEHICLE).subscribe((data) => {
              this.appState.setState({
                claims: claims ? claims : null,
                vehicle: DEFAULT_VEHICLE,
                baseline: data.grid,
                segments: data.frontend_non_grid.segment,
              });
              this.claims = claims;

              // upon login, redirect to landing-page or the page user was going to
              if (window.location.pathname === '/') {
                this.router.navigateByUrl('/landing-page');
              } else {
                this.router.navigateByUrl(window.location.pathname);
              }
              resolveFn();
            });
          } else {
            rejectFn();
          }
        });
    });
  }
}
