import { Component, Input, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UIStateService } from '../../services/ui-state.service';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.css'],
})
export class EmbedComponent implements OnInit, AfterViewInit {
  @Input() src: string;
  @Input() user_name: string;
  @Input() scenario_name: string;
  email: string;
  @ViewChild('vizFilterUserName', { static: false }) vizFilterUserName?: ElementRef;
  @ViewChild('vizFilterScenario', { static: false }) vizFilterScenario?: ElementRef;
  token;
  last_modified_date;
  constructor(
    private apiService: ApiService,
    readonly appState: UIStateService,
    private renderer: Renderer2,
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        if (Object.keys(state).length > 0) {
          const { email } = state.claims;
          this.email = email;
          this.last_modified_date = state.last_modified_date;
          this.token = state.token;
        }
      });
  }

  public getScreenWidth: any;
  public getScreenHeight: any;
  private calculateDashboardSize = () => {
    const bufferSize = 25;
    this.getScreenWidth = window.innerWidth - bufferSize;
    this.getScreenHeight = (window.innerWidth - bufferSize);
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.calculateDashboardSize();
  }


  ngOnInit() {
    this.calculateDashboardSize();
  }

  // https://stackoverflow.com/questions/76708425/trouble-passing-filter-viz-filter-value-from-angular-application-to-embedded-w
  ngAfterViewInit(): void {
    if (this.vizFilterUserName) {
      const username = this.user_name ? this.user_name : this.email;
      this.renderer.setAttribute(this.vizFilterUserName.nativeElement, 'value', username);
    }
    if (this.vizFilterScenario) {
      const scenario = this.scenario_name ? this.scenario_name : 'all';
      this.renderer.setAttribute(this.vizFilterScenario.nativeElement, 'value', scenario);
    }
  }
}
