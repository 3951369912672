import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UIStateService {
  private state = {};
  private state$ = new BehaviorSubject<any>(this.state);
  constructor() {}
//
  getState$() {
    return this.state$.asObservable();
  }

  getCurrentState() {
    return this.state;
  }
  setState(state) {
    this.state = { ...this.state, ...state };
    this.state$.next(this.state);
  }
  clearState() {
    this.state = {};
    this.state$.next(this.state);
  }
}
