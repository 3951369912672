import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-titles',
  templateUrl: './titles.component.html',
  styleUrls: ['./titles.component.css'],
})
export class TitlesComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() action: string;
}
