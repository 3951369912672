import { Component } from '@angular/core';
import {UIStateService} from '../../services/ui-state.service';
import {DEFAULT_VEHICLE} from '../../config/config';
import {getBasePath} from '../../config/getInsightsBasePath';

@Component({
  selector: 'app-customer-preference',
  templateUrl: './customer-preference.component.html',
  styleUrls: ['./customer-preference.component.css'],
})
export class CustomerPreferenceComponent {
  claims;
  path_311D: string;
  path_311D_OP: string;
  path_400D: string;
  path_SIENNA: string;
  selected_model: string;
  constructor(
    readonly appState: UIStateService
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        this.selected_model = state.vehicle ? state.vehicle : DEFAULT_VEHICLE;
      });
    const basePath = getBasePath();
    this.path_311D = basePath['311D'];
    this.path_311D_OP = basePath['311D_OP'];
    this.path_400D = basePath['400D'];
    this.path_SIENNA = basePath['SIENNA']
  }
}
