<app-header-nav current_tab="/landing-page"></app-header-nav>
<div class="page-container">
  <div class="intro-container">
    <h1 style="font-weight: 700; display: block; font-size: 120px">CVO 1.0</h1>
    <h2>Putting the Customer at the Center of Optimizing for Value</h2>
    <section class="body-copy">
      CVO is an optimization and insights tool to support decision making in
      determining the optimal vehicle content to maximize customer value. CVO
      enables planners to:
    </section>
    <ul>
      <li>Understand what a customer values and their preferences</li>
      <li>Simulate and estimate the impact of vehicle content changes</li>
      <li>Understand what features customers are likely to substitute between</li>
    </ul>
  </div>
  <div class="get-started-container">
    <div routerLink="/opportunities" class="get-started-link">
      Get Started
    </div>
  </div>
</div>
