<app-header-nav current_tab="/opportunities" show_model_select="true"></app-header-nav>
<div class="page-container" style="background-repeat: repeat !important">
  <div *ngIf="selected_model === '311D'">
    <div style="margin: 20px 20px 0 40px; color: white">
      <h1 style="margin-bottom: 15px">Potential Opportunities to Explore</h1>
      <h4>A consolidated list of example opportunities identified by the CVO team</h4>
      <p style="margin-top: 5px">
        Click "Explore" to jump to View Scenarios page and select the recommendation from the dropdown
      </p>
    </div>
    <div class="carousel-container">
      <mat-card class="carousel-card" *ngFor="let carousel of insights_carousel">
        <img class="carousel-img" mat-card-image [src]="carousel.picture" />
        <mat-card-header class="card-header">
          <mat-card-title>{{ carousel.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p style="text-align: center">{{ carousel.content }}</p>
        </mat-card-content>
        <mat-card-actions class="card-actions">
          <a [routerLink]="carousel.link">
            <button mat-button>
              Explore <span style="color: red"> &#62;</span>
            </button>
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="embed-page-container">
    <app-titles
      title="Thought Starters for Additional Opportunities"
      subtitle="Quick insights compared to the planned offering"
      action="Scroll through the list below and identify potential categories to investigate further"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/2OpportunitiesRecommendations"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/2OpportunitiesRecommendations"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/2OpportunitiesRecommendations"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/2OpportunitiesRecommendations"/>
  </div>
  <div class="embed-page-container">
    <app-titles
      title="Customer Reach Within a Category"
      subtitle="To reach a desired customer reach, show the combinations of features that need to be offered to satisfy customer’s top N choices within a category"
      action="Utilize filters to select a category, adjust the percent reach of customers to be covered, select customer's top N number of choices to be satisfied, and toggle by maximum number of features to offer within a category"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/2Opportunities"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/2Opportunities"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/2Opportunities"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/2Opportunities"/>

  </div>
</div>
