<app-header-nav current_tab="/market-insights" show_model_select="true"></app-header-nav>
<div class="page-container">
  <div class="embed-page-container" *ngIf="selected_model !== '400D'">
    <app-titles
      title="Competitor Mapping (JATO)"
      subtitle="Identify what features competitors are offering vs Toyota at a trim level"
      action="Utilize filters to select a specific category, and use both “comparison set” filters to map against specific competitors/trims"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/7MarketInsights13"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/7MarketInsights13"/>
  </div>
  <div class="embed-page-container">
    <app-titles
      title="T.Com Click to Purchase Matrix"
      subtitle="By matching customer requests on T.com to actual VINs purchased, shows what a user requested vs. what they ended up purchasing"
      action="Utilize filters to select a specific category, and toggle inventory availability(“Unavailable Only” indicates item was not in inventory when configured) and TMNA region to see customer quote-to-purchase conversion"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/7MarketInsights23"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/7MarketInsights23"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/7MarketInsights23"/>
  </div>
  <div class="embed-page-container" *ngIf="selected_model !== '400D'">
    <app-titles
      title="Historical Data"
      subtitle="By mapping the conjoint features back to historical TMNA offering:
      1. Take Rates (top graph) show the % of vehicles within a specific model year that contained a feature.
      2. Average Days on Lot (bottom graph) shows the average time vehicles with an indicated feature remains on lot before a purchase"
      action="Filter on category to understand historical performance for each feature"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/7MarketInsights33"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/7MarketInsights33"/>
  </div>
</div>
