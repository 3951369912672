import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { UIStateService } from 'src/app/services/ui-state.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent {
  baseline_url;
  last_modified_date;
  vehicle;
  constructor(
    public dialogRef: MatDialogRef<FaqComponent>,
    private appState: UIStateService,
    private apiService: ApiService
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        const { sub } = state.claims;
        const { vehicle } = state;
        this.vehicle = vehicle;
        this.apiService.getConfig(vehicle, sub).subscribe((data) => {
          this.baseline_url = data.baseline_url;
          this.last_modified_date = data.last_modified_date.split(',')[0];
        });
      });
  }
}
