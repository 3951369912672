<app-header-nav current_tab="/scenario-output"></app-header-nav>
<mat-form-field>
  <mat-label>Select a Project</mat-label>
  <mat-select
    [(ngModel)]="selected_model"
    (selectionChange)="onModelChange()"
  >
    <mat-option
      *ngFor="let model of models"
      [value]="model"
    >
      {{ model }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="page-container" *ngIf="user_name">
  <app-embed
    [src]="scenario_output_path"
    [user_name]="user_name"
    [scenario_name]="scenario_name"
  ></app-embed>
</div>
