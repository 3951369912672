export const config = {
  apiBase: {
    dev: 'https://1agadk3efc.execute-api.us-west-2.amazonaws.com/',
    qa: 'https://hisu1fxor6.execute-api.us-west-2.amazonaws.com/',
  },
  configRoute: 'config',
  baselineRoute: 'retrieve-simulation-input',
  simulationRoute: 'post-simulation',
};

export const authCodeFlowConfig = {
  local: {
    issuer: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily',
    redirectUri: 'http://localhost:4200/landing-page',
    postLogoutRedirectUri: 'http://localhost:4200',
    clientId: 'CVO',
    responseType: 'code',
    scope: 'openid profile',
    showDebugInformation: true,
  },
  dev: {
    issuer: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily',
    redirectUri: 'https://one.cvodev.toyota.com',
    postLogoutRedirectUri: 'https://one.cvodev.toyota.com',
    clientId: 'CVO',
    responseType: 'code',
    scope: 'openid profile',
    showDebugInformation: true
  },
  qa: {
    issuer: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily',
    redirectUri: 'https://one.cvoqa.toyota.com',
    postLogoutRedirectUri: 'https://one.cvoqa.toyota.com',
    clientId: 'CVO',
    responseType: 'code',
    scope: 'openid profile',
    showDebugInformation: true
  },
  prod: {
    issuer: 'https://ep.fram.idm.toyota.com/oauth2/realms/root/realms/dealerdaily',
    redirectUri: 'https://one.cvo.toyota.com',
    postLogoutRedirectUri: 'https://one.cvo.toyota.com',
    clientId: 'CVO',
    responseType: 'code',
    scope: 'openid profile',
    showDebugInformation: true
  }
};

export const MARKET_INSIGHTS_EMBEDDINGS = {
  dev: {
    '311D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_dev_insights',
    '311D_OP': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_op_dev_insights',
    '400D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/400D_dev_insights',
    'SIENNA': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/Sienna_dev_insights'
  },
  qa: {
    '311D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_qa_insights',
    '311D_OP': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_OP_qa_insights',
    '400D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/400D_qa_insights'
  },
  prod: {
    '311D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_prod_insights',
    '311D_OP': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_OP_prod_insights',
    '400D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/400D_prod_insights'
  }
};

export const SCENARIO_OUTPUT_EMBEDDINGS = {
  dev: {
    '311D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_dev_scenario_output/8ScenarioOutputEmbed2',
    '311D_OP' : 'https://ssbi-ddc-qa.toyota.com/#/site/DSM/views/311D_op_dev_scenario_output/8ScenarioOutputEmbed2',
    '400D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/400D_dev_scenario_output/8ScenarioOutputEmbed2',
    'SIENNA': 'https://ssbi-ddc-qa.toyota.com/#/site/DSM/views/Sienna_dev_scenario_output/8ScenarioOutputEmbed2'
  },
  qa: {
    '311D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_qa_scenario_output/8ScenarioOutputEmbed2',
    '311D_OP' : 'https://ssbi-ddc-qa.toyota.com/#/site/DSM/views/311D_op_qa_scenario_output/8ScenarioOutputEmbed2',
    '400D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/400D_qa_scenario_output/8ScenarioOutputEmbed2'
  },
  prod: {
    '311D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/311D_prod_scenario_output/8ScenarioOutputEmbed2',
    '311D_OP' : 'https://ssbi-ddc-qa.toyota.com/#/site/DSM/views/311D_op_dev_scenario_output/8ScenarioOutputEmbed2',
    '400D': 'https://ssbi-ddc-qa.toyota.com/t/DSM/views/400D_prod_scenario_output/8ScenarioOutputEmbed2'
  }
};

export const COLOR_MAPPINGS = {
  1: {
    color: '#9F9D65',
    description: 'Standard',
    font_color: 'black',
    symbol: 'S',
  },
  2: {
    color: '#D2D092',
    description: 'Optional',
    font_color: 'black',
    symbol: 'O',
  },
  0: {
    color: '#EC8F75',
    description: 'Not currently included',
    font_color: 'black',
    symbol: 'X',
  },
};

export const DEFAULT_VEHICLE = '311D';

export const ALL_VEHICLES = ['311D', '311D_OP', '400D', 'SIENNA'];

export const TABS_LIST = [
  { special: 'false', icon: 'home', url: '/landing-page', text: 'Home' },
  {
    special: 'false',
    url: '/opportunities',
    text: 'Opportunities',
  },
  {
    special: 'false',
    url: '/customer-preference',
    text: 'Customer Preferences',
  },
  {
    special: 'false',
    url: '/feature-changes',
    text: 'Feature Modifications',
  },
  {
    special: 'false',
    url: '/market-insights',
    text: 'Market Insights',
  },
  {
    special: 'false',
    url: '/create-scenario',
    text: 'Create \'What-if\' Scenario',
  },
  {
    special: 'false',
    url: '/scenario-output',
    text: 'View Scenarios',
  },
];

export const INSIGHTS_CAROUSEL = [
  {
    picture: '../../assets/img/carousel-1.png',
    title: '90%+',
    content: 'of customers want heated seats',
    link: '/scenario-output',
  },
  {
    picture: '../../assets/img/carousel-2.png',
    title: 'XLE',
    content: 'is 36% of people’s 1st or 2nd choice',
    link: '/scenario-output',
  },
  {
    picture: '../../assets/img/3 Color Options.png',
    title: '3 Colors',
    content: 'would give 75% of customers their top choice',
    link: '/scenario-output',
  },
  {
    picture: '../../assets/img/carousel-4.png',
    title: '88%',
    content: 'of customers want one of two headlight options',
    link: '/scenario-output',
  },
  {
    picture: '../../assets/img/carousel-5.png',
    title: 'Only 5%',
    content: 'of customers have Nutmeg as their top interior',
    link: '/scenario-output',
  },
];
