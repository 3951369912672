import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CreateScenarioComponent } from './pages/create-scenario/create-scenario.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { OpportunitiesComponent } from './pages/opportunities/opportunities.component';
import { FeatureChangesComponent } from './pages/feature-changes/feature-changes.component';
import { CustomerPreferenceComponent } from './pages/customer-preference/customer-preference.component';
import { MarketInsightsComponent } from './pages/market-insights/market-insights.component';
import { ScenarioOutputComponent } from './pages/scenario-output/scenario-output.component';
import { AppComponent } from './app.component';
const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'landing-page', component: LandingPageComponent },
  { path: 'opportunities', component: OpportunitiesComponent },
  { path: 'feature-changes', component: FeatureChangesComponent },
  { path: 'customer-preference', component: CustomerPreferenceComponent },
  { path: 'market-insights', component: MarketInsightsComponent },
  { path: 'create-scenario', component: CreateScenarioComponent },
  { path: 'scenario-output', component: ScenarioOutputComponent },
  { path: '**', component: AppComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
