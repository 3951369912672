import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';

import { GridComponent } from './components/grid/grid.component';
import { CreateScenarioComponent } from './pages/create-scenario/create-scenario.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LandingCardComponent } from './components/landing-card/landing-card.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { MatDialogModule } from '@angular/material/dialog';

import { MatDividerModule } from '@angular/material/divider';
import { OpportunitiesComponent } from './pages/opportunities/opportunities.component';
import { FeatureChangesComponent } from './pages/feature-changes/feature-changes.component';
import { CustomerPreferenceComponent } from './pages/customer-preference/customer-preference.component';
import { MarketInsightsComponent } from './pages/market-insights/market-insights.component';
import { ScenarioOutputComponent } from './pages/scenario-output/scenario-output.component';
import { EmbedComponent } from './components/embed/embed.component';
import { NextPageComponent } from './components/next-page/next-page.component';
import { config } from './config/config';
import { FaqComponent } from './components/faq/faq.component';
import { TitlesComponent } from './components/titles/titles.component';

@NgModule({
  declarations: [
    AppComponent,
    GridComponent,
    CreateScenarioComponent,
    LandingPageComponent,
    LandingCardComponent,
    HeaderNavComponent,
    OpportunitiesComponent,
    FeatureChangesComponent,
    CustomerPreferenceComponent,
    MarketInsightsComponent,
    ScenarioOutputComponent,
    EmbedComponent,
    NextPageComponent,
    FaqComponent,
    TitlesComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDividerModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [config.apiBase.dev, config.apiBase.qa],
        sendAccessToken: true,
      },
    }),
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
