import {MARKET_INSIGHTS_EMBEDDINGS} from './config';

export const getBasePath = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === 'one.cvodev.toyota.com') {
    return MARKET_INSIGHTS_EMBEDDINGS.dev;
  } else if (window.location.hostname === 'one.cvoqa.toyota.com') {
    return MARKET_INSIGHTS_EMBEDDINGS.qa;
  } else if (window.location.hostname === 'one.cvo.toyota.com') {
    return MARKET_INSIGHTS_EMBEDDINGS.prod;
  }
};
