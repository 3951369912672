<div class="faq">
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Key Contacts: </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        For questions or requested changes/additional features please reach out
        to Kevin Fukuchi and Chris Kelly
      </p>
    </mat-expansion-panel>
    <h4 class="faq-heading">Data and Methodology:</h4>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          1. What data is used to generate the numbers and insights?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        In combination with other sources, CVO quantifies customer preferences
        using a conjoint survey - where respondents go through a
        configurator-type ‘build-your-own vehicle’ to capture data. As the
        customer trades-off choices, and determines how likely they are to buy
        the built vehicle - we are able to derive their importance for specific
        features. It was conducted in Q2 2023, with 2400 respondents
      </p>
      <ol>
        <li>
          <u style="font-weight: 700">Screening criteria:</u> Screens for
          respondents who qualify for our set criteria (e.g., a future RAV4
          purchaser [intenders] or those who purchased a RAV4 in the past 12
          months [past-purchasers])
        </li>
        <li>
          <u style="font-weight: 700"
            >Demographic breakdown and weighting methodology:</u
          >
          Since we sample a smaller set of of people than our target market, our
          sample is weighted to car buying market for the specific segment
          (e.g., Mid-sized SUVs). For example, if we expect 60% of sales to come
          from people aged 30-40, but only 50% of our sample is from that age
          bucket - their individual choices are considered more in the analysis.
        </li>
        <li>
          <u style="font-weight: 700">Conjoint Analysis using Max Diff:</u> Menu
          based conjoint is a format of conjoint that allows the survey taker to
          build their own ideal vehicle given the vehicle features that they
          identified as most important to them in the survey screener known as
          the MaxDiff. Each vehicle feature to build with has a subset of
          options to choose from, and the final vehicle is the respondents ideal
          vehicle that they could have built from the available choices. This
          format closely resembles the online vehicle configurator that actual
          customers and website visitors interact with.
        </li>
        <li>
          <u style="font-weight: 700"
            >What categories and features were tested? Is pricing included?</u
          >
          The list of categories and features tested were identified by working
          in conjunction with PPS teams to reflect current offerings and future
          features that may be offered. Each feature tested has an associated
          price – the value aligned between TMC and PPS. If the feature does not
          have established spec value, the PPS planning group responsible for
          vehicle project determines the price to be tested. The survey takers
          then see the price associated with the feature when going through the
          ‘build-your-own vehicle' excercise.
        </li>
      </ol>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          2. How is the volume impact estimated?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Through understanding customer preferences, we are able to model how
        likely customers are to switch between choices, we can tell if a)
        customers stick with their own choice, b) customers switch offerings, c)
        customers leave (or come in) in the event of an addition or removal of a
        feature
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> 3. How often is the data refreshed? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Customer preferences and volume impacts will be updated when additional
        surveys are conducted and new data is available (e.g., for a full model
        year change (pre DDM)). Market insights (e.g., click data, JATO, take
        rates and days on lot) are updated on an ongoing basis
      </p>
    </mat-expansion-panel>
    <h4 class="faq-heading">Market Insights:</h4>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          1. How was the JATO mapping conducted?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Mapping between JATO and the conjoint categories and features was done
        by manual comparison. The competitor set was identified by PPS, data
        pulled from JATO, and then manually compared in Excel
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          2. How will JATO mapping be maintained going forwards?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Currently, the process of mapping Toyota features to competitor features
        is manual and requires PPS insight. In the future, there is a
        possibility to automate this.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          3. What is the data source for click data and how is it computed?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        The click data matrix is derived from 3 primary datasets: toyota.com
        click stream, pipeline_tl table from TBDP, and the leads data from ELMS.
        Click stream is linked to ELMS via the toyota.com transaction ID and
        then linked to the pipeline_tl on the VIN. This dataset only contains
        leads that converted to sales.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          4. What are the data sources for take rates and days on lot? How are
          they computed?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ol>
        <li>
          Take rates and days on lot are computed from the pipeline_tl table.
        </li>
        <li>
          All sold vehicles are considered for all model years since 2019.
        </li>
        <li>
          Days on lot is computed from the same dataset by find the difference
          between the date the vehicle arrived in DEALER STOCK and when it was
          marked as RETAIL SALE. The DEALER STOCK transactions in that table are
          only updated monthly, so the sample size is fairly low due to the high
          sales velocity
        </li>
        <li>The mapping between conjoint and historical was done manually</li>
      </ol>
    </mat-expansion-panel>

    <h4 class="faq-heading">Creating and Saving Scenarios:</h4>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          1. What baseline is the scenario comparing to?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        VSI version: {{vehicle}} VSI as of {{last_modified_date}}
        <!-- VSI version: <a [href]="baseline_url"> 311D VSI as of 08.30.2022 </a> -->
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          2. If an updated VSI is released, what do I do?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Baselines can be updated by following the steps outlined here:
        https://toyota.atlassian.net/wiki/spaces/CVO/pages/369905329
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >3. How do I save or share the dashboards/scenario output?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ol>
        <li>
          Exporting output: Located share button in the bottom toolbar (on every
          dashboard) and choose to export it as an image or PDF, among other
          formats.
        </li>

        <li>
          Sharing output: You can choose to share a dashboard or a scenario
          output by either exporting the view as a image/PDF, or by inviting
          them to access CVO to view the insights/dashboards and past scenarios
        </li>
      </ol>
    </mat-expansion-panel>
    <h4 class="faq-heading">Customer Value Optimization (CVO) Next Steps:</h4>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>1. What’s coming next? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>CVO 2.0 will include:</p>
      <ol>
        Multi-Variable Scenarios: Predict customer value changes with any
        configuration
      </ol>
      <ol>
        Deep-choice model: A smarter algorithm to help refine impact estimations
        and simulate more changes simultaneously
      </ol>
      <ol>
        Margin Calculator: Provide visibility of making complexity trade-off
        decisions
      </ol>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >2. How will we scale this to other series?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        A new series likely requires a new conjoint survey to be undertaken
        (unless it can be inferred from existing surveys). Contact Kevin Fukuchi
        to discuss any planned series that may be coming to CVO.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
