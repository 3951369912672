import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

import {
  scenarioBaseline,
  scenarioResponse,
  scenarioBody,
  configResponse,
} from '../interfaces/scenario';
import {config} from '../config/config';

import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})

export class ApiService {
  basePath: string = window.location.hostname === 'localhost' || window.location.hostname === 'one.cvodev.toyota.com' ?
    config.apiBase.dev :
    config.apiBase.qa;
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  getConfig(vehicle: string, sub: string): Observable<configResponse> {
    return this.http.post<configResponse>(
      `${this.basePath}${config.configRoute}`,
      {
        vehicle,
        sub
      }
    ).pipe(
      catchError(this.handleError)
    );
  }

  getBaseline(vehicle: string): Observable<scenarioBaseline> {
    return this.http.post<scenarioBaseline>(
      `${this.basePath}${config.baselineRoute}`,
      { vehicle }
    ).pipe(
      catchError(this.handleError)
    );
  }
  submitScenario(body: scenarioBody): Observable<scenarioResponse> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>(
      `${this.basePath}${config.simulationRoute}`,
      body,
      { headers }
    ).pipe(
      catchError(this.handleError)
    );
  }

  logError(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  // Global API error handler
  private handleError = (error: HttpErrorResponse) => {
    // Add additional response code handling here as scenarios arise.
    console.log(error);
    switch (error.status) {
      case 0:
        this.logError(`An unknown error occurred, please try again`);
        console.error('An error occurred:', error);
        break;
      case 403:
        this.logError('Your user authentication token is expired. Please refresh the page!');
        console.error(`Backend returned code ${error.status}, `, error);
        break;
      default:
        this.logError(`Backend returned code ${error.status}, ${error.statusText}`);
        console.error(`Backend returned code ${error.status}, `, error);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
