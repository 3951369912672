<nav>
  <div class="header-row-one">
    <div class="icon-link-container">
      <a routerLink="/landing-page">
        <img src="..\..\..\assets\img\Toyota Logo.svg" style="width: 50px"/>
      </a>
      <h2>Customer Value Optimization (CVO)</h2>
    </div>
    <div class="profile-container">
      <button mat-raised-button (click)="openFAQ()">
        FAQ
      </button>
      <button mat-raised-button (click)="logout()">
        Logout
      </button>
      <mat-icon class="enlarged-icon">person</mat-icon>
      <div *ngIf="claims">
        {{ claims.preferred_name }}
      </div>
    </div>
  </div>

  <div class="header-row-two">
    <div
      class="nav-text-container"
      [routerLink]="tab.url"
      *ngFor="let tab of TABS_LIST; index as i"
    >
      <div [style.color]="current_tab == tab.url ? 'red' : 'black'">
        {{ tab.text }}
      </div>
      <div
        style="height: 2px"
        [style.border-bottom]="current_tab == tab.url ? '3px solid red' : '3px solid white'"
        [style.width]="current_tab == tab.url ? '70%' : '1%'"
      ></div>
    </div>
  </div>
  <mat-form-field *ngIf="show_model_select" style="margin:0 0 -10px 10px;">
    <mat-label>Select a Project</mat-label>
    <mat-select [(ngModel)]="selected_model" (selectionChange)="onModelChange()" disableRipple="true">
      <mat-option *ngFor="let model of models" [value]="model">{{ model }}</mat-option>
    </mat-select>
  </mat-form-field>
</nav>
