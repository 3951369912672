<div class="page-container">
  <div *ngIf="show_spinner">
    <mat-spinner style="margin: 100px auto auto;"></mat-spinner>
  </div>
  <div *ngIf="!show_spinner" class="grid-row">
    <div class="input-section">
      <div>
        <mat-form-field>
          <mat-label>Enter a scenario name</mat-label>
          <input
            matInput
            [(ngModel)]="scenario_name"
            (keyup)="checkShowTable()"
            placeholder="Enter a name for this scenario"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Select a Project</mat-label>
          <mat-select
            [(ngModel)]="selected_model"
            (selectionChange)="onModelChange()"
          >
            <mat-option
              *ngFor="let model of models"
              [value]="model"
            >
              {{ model }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Select a category</mat-label>
          <input
            type="text"
            placeholder="Enter to search"
            aria-label="Number"
            matInput
            [formControl]="form_control"
            [matAutocomplete]="auto"
            [(ngModel)]="category_value"
          />
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearOption()"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete
            (optionSelected)="onCategoryChange($event.option.value)"
            #auto="matAutocomplete"
          >
            <mat-option
              *ngFor="let option of filtered_options | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Select a segment</mat-label>
          <mat-select
            [(ngModel)]="selected_segment"
            (selectionChange)="onSegmentChange()"
          >
            <mat-option
              *ngFor="let segment of segments"
              [value]="segment.segment"
              >{{ segment.segment_displayed }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="table-container">
      <div class="scenario-container">
        <h3 *ngIf="show_table" style="margin-top: 15px">
          Click on a cell to change state
        </h3>
        <div *ngIf="show_table" class="legend-container">
          <div class="legend-key" *ngFor="let legend of color_mappings | keyvalue">
            <div
              [style.background-color]="legend.value.color"
              [style.color]="legend.value.font_color"
              class="legend-box"
            >
              {{ legend.value.symbol }}
            </div>
            <div>{{ legend.value.description }}</div>
          </div>
        </div>
        <div class="grid-container">
          <h3 *ngIf="!show_table" style="margin-top: 45px">
            Give the scenario a name and select a category to see the baseline configuration
          </h3>
          <table
            *ngIf="show_table"
            mat-table
            [dataSource]="datasource"
            class="mat-elevation-z8 grid-table"
          >
            <div *ngFor="let ordered_array of this.ordered_display_headers; index as outerI">
              <div *ngFor="let column of ordered_array; first as firstColumn; index as innerI">
                <ng-container [matColumnDef]="column.label + column.parent" *ngIf="firstColumn" sticky>
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-column-label"
                    [style.background-color]="(outerI > 0) && 'lightskyblue'"
                    [attr.colspan]="column.span"
                  >
                    {{column.label}}
                  </th>
                </ng-container>
                <ng-container [matColumnDef]="column.label + column.parent"  *ngIf="!firstColumn">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="table-header-label"
                    [style.background-color]="(outerI > 0) && 'lightskyblue'"
                    [attr.colspan]="column.span"
                  >
                    {{ column.label }}
                  </th>
                </ng-container>
              </div>
            </div>
            <div *ngFor="let column of all_columns; first as isFirst; index as i">
              <ng-container [matColumnDef]="column" *ngIf="isFirst" sticky>
                <td
                  mat-cell
                  style="width: 60px; word-wrap: break-word"
                  *matCellDef="let element"
                  class="mat-mdc-table-sticky"
                  sticky
                >
                  {{ element[column] }}
                </td>
              </ng-container>
              <ng-container [matColumnDef]="column" [style.display]="i == 1 ? 'none' : 'initial'" *ngIf="!isFirst">
                <td
                  mat-cell
                  *matCellDef="let element"
                  [style.border-right]="border_right_array.includes(i) ? 'solid 1px black' : 'none'"
                >
                  <mat-cell class="cell-wrapper">
                    <button
                      [style.background-color]="
                        element[column] > -1
                          ? element[column] > 0
                            ? element[column] > 1
                              ? '#D2D092'
                              : '#9F9D65'
                            : '#EC8F75'
                          : 'black'
                      "
                      [style.color]="element[column] == -1 ? 'white' : 'black'"
                      class="cell-button"
                      (click)="changeElement(element, column)"
                    >
                      {{ color_mappings[element[column]].symbol }}
                    </button>
                  </mat-cell>
                </td>
              </ng-container>
            </div>
            <div *ngFor="let ordered_array of this.mat_table_headers_array">
              <tr mat-header-row *matHeaderRowDef="ordered_array"></tr>
            </div>
            <tr mat-row *matRowDef="let row; columns: all_columns"></tr>
          </table>

        </div>
      </div>
      <div *ngIf="show_table" class="button-container">
        <button
          class="reset-button"
          mat-raised-button
          color="link"
          (click)="resetGrid()"
        >
          Reset
        </button>
        <mat-card class="landing-card" (click)="submitScenario()">
          <mat-card-header class="legend-container">
            <img
              style="width: 48px; height: 40px"
              src="..\..\..\assets\img\rocket.png"
              alt="rocket"
            />
            <div>
              <mat-card-title
                ><h4 style="color: white">Create Scenario</h4></mat-card-title
              >
            </div>
          </mat-card-header>
        </mat-card>
        <div style="color: red" *ngIf="has_validation_error">
          {{ validation_error_message }}
        </div>
      </div>
    </div>
  </div>
</div>
