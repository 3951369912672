import { Component, Input } from '@angular/core';
import { UIStateService } from '../../services/ui-state.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import {ALL_VEHICLES, DEFAULT_VEHICLE, TABS_LIST} from '../../config/config';
import { MatDialog } from '@angular/material/dialog';
import { FaqComponent } from '../faq/faq.component';
@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css'],
})
export class HeaderNavComponent {
  @Input() current_tab: string;
  @Input() show_model_select: boolean;
  claims: any;
  TABS_LIST = TABS_LIST;
  models: string[] = ALL_VEHICLES;
  selected_model: string;
  constructor(
    private appState: UIStateService,
    private oauthService: OAuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        this.claims = state.claims;
        this.selected_model = state.vehicle ? state.vehicle : DEFAULT_VEHICLE;
      });
  }

  logout() {
    this.oauthService.revokeTokenAndLogout();
    this.oauthService.logOut();
    this.router.navigateByUrl('/');
  }
  openFAQ() {
    this.dialog.open(FaqComponent, { height: '800px', width: '1200px' });
  }
  onModelChange() {
    this.appState.setState({
      vehicle: this.selected_model,
    });
  }
}
