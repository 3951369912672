<div class="tableau-wrapper" *ngIf="token && src">
  <p class="date-text" *ngIf="last_modified_date">
    as of {{last_modified_date}}
  </p>
  <tableau-viz id="tableauViz" [src]="src" [token]="token">
    <viz-filter #vizFilterUserName field="user_name"> </viz-filter>
    <viz-filter #vizFilterScenario field="Scenario Name"> </viz-filter>
    <viz-filter field="Slice Category" value="All"> </viz-filter>
    <viz-filter field="Slice Name" value="All"> </viz-filter>
  </tableau-viz>
</div>
