import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {ALL_VEHICLES, DEFAULT_VEHICLE, SCENARIO_OUTPUT_EMBEDDINGS} from 'src/app/config/config';
import {UIStateService} from '../../services/ui-state.service';

@Component({
  selector: 'app-scenario-output',
  templateUrl: './scenario-output.component.html',
  styleUrls: ['./scenario-output.component.css'],
})
export class ScenarioOutputComponent {
  user_name: string;
  scenario_name: string;
  scenario_output_path: string;
  models: string[] = ALL_VEHICLES;
  selected_model: string;
  constructor(
    private router: Router,
    readonly appState: UIStateService,
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        this.selected_model = state.vehicle ? state.vehicle : DEFAULT_VEHICLE;
        if (!this.user_name) { this.user_name = state.claims.email; }
      });
    if (
      this.router
        .getCurrentNavigation()
        .extras?.state?.hasOwnProperty('user_name') &&
      this.router
        .getCurrentNavigation()
        .extras?.state?.hasOwnProperty('scenario_name')
    ) {
      this.user_name =
        this.router.getCurrentNavigation().extras.state.user_name;
      this.scenario_name =
        this.router.getCurrentNavigation().extras.state.scenario_name;
    }

    this.setScenarioOutputPath();
  }

  setScenarioOutputPath() {
    if (window.location.hostname === 'localhost' || window.location.hostname === 'one.cvodev.toyota.com') {
      this.scenario_output_path = SCENARIO_OUTPUT_EMBEDDINGS.dev[this.selected_model];
    } else if (window.location.hostname === 'one.cvoqa.toyota.com') {
      this.scenario_output_path = SCENARIO_OUTPUT_EMBEDDINGS.qa[this.selected_model];
    } else if (window.location.hostname === 'one.cvo.toyota.com') {
      this.scenario_output_path = SCENARIO_OUTPUT_EMBEDDINGS.prod[this.selected_model];
    }
  }

  onModelChange() {
    this.setScenarioOutputPath();
  }
}
